import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm() {
	const [state, handleSubmit] = useForm("mbjpngbl");
	if (state.succeeded) {
		return (window.location = "/thank-you");
	}
	return (
		<form onSubmit={handleSubmit}>
			<Form.Group className="pb-3" controlId="name">
				<Form.Control placeholder="Name" type="text" name="name" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="pb-3" controlId="email">
				<Form.Control
					placeholder="Email Address"
					type="email"
					name="_replyto"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="pb-3" controlId="telephone">
				<Form.Control
					placeholder="Telephone Number"
					type="tel"
					name="telephone"
				/>
			</Form.Group>
			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>

			<Form.Group className="pb-3" controlId="source">
				<Form.Control
					placeholder="How did you hear about me?"
					type="text"
					name="source"
				/>
			</Form.Group>
			<ValidationError prefix="Source" field="source" errors={state.errors} />
			<Form.Group className="pb-3" controlId="subject">
				<Form.Control placeholder="Subject" type="text" name="subject" />
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />

			<Form.Group className="pb-3" controlId="message">
				<Form.Control
					as="textarea"
					rows="3"
					name="message"
					placeholder="What do you want to ask me?"
				/>
			</Form.Group>
			<ValidationError prefix="Message" field="message" errors={state.errors} />

			<div
				class="g-recaptcha mb-3"
				data-sitekey="6LevpHsaAAAAAAE242NqVTi-wdnJZT590rW2xCK8"
			></div>
			<Button
				className="btn btn-primary mt-3"
				id="contact-send-btn"
				type="submit"
				disabled={state.submitting}
			>
				Send Message
			</Button>
		</form>
	);
}
export default ContactForm;
