import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
		}
	`);

	const html = `<form
                action="https://formspree.io/f/mbjpngbl"
                method="POST">
                <div class="form-group">
                    <input class="form-control" placeholder="Name" type="text" name="name">
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Email Address" type="email" name="_replyto">    
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Telephone Number" type="text" name="telephone">    
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="How did you hear about me?" type="text" name="source">    
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Subject" type="text" name="subject">    
                </div>
                <div class="form-group">
                <textarea class="form-control" rows="3" name="message" placeholder="What do you want to ask me?"></textarea>      
                </div>   
                <div class="g-recaptcha" data-sitekey="6LevpHsaAAAAAAE242NqVTi-wdnJZT590rW2xCK8"></div>
            
                <button class="btn btn-primary" type="submit">Send Message</button>
                </div>
                
                </form>`;

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>

			<GatsbySeo
				title="Contact Me | Sarah Bennett Commercial Photography"
				description="Lets talk photos! For more information on my commercial photography services in London and Sussex or for a free quote, click now to contact me."
				language="en"
				canonical="https://www.sarah-bennett.com/contact-us"
				openGraph={{
					url: "https://www.sarah-bennett.com/contact-us",
					title: "Contact Me | Sarah Bennett Commercial Photography",
					description:
						"Lets talk photos! For more information on my commercial photography services in London and Sussex or for a free quote, click now to contact me.",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Product Photography East & West Sussex",
						},
					],
				}}
			/>
			<section>
				<Container>
					<Row className="mt-6">
						<Col className="text-center">
							<h1>Contact Us</h1>
							<hr className="w-25 mx-auto" style={{ opacity: 1 }} />
						</Col>
					</Row>
					<Row className="mt-5">
						<Col className="text-center">
							<p>105 Hurst Road, Eastbourne, BN21 2PN</p>
							<a
								className="text-dark email-link"
								href="mailto:sarah@sarah-bennett.com"
							>
								<p>sarah@sarah-bennett.com</p>
							</a>
							<a className="text-dark phone-number" href="tel:01323384672">
								<p>01323 384 672</p>
							</a>
						</Col>
					</Row>
					<Row className="">
						<Col className="text-center">
							<ul className="p-0 ">
								<li className="d-inline-block">
									<a
										rel="noreferrer"
										className="text-dark pe-4 social-icon"
										style={socialIcon}
										href="https://www.youtube.com/channel/UCjC3FRZA57srjsyQT36XAqA?view_as=subscriber"
										target="_blank"
									>
										<FaYoutube />
									</a>
								</li>
								<li className="d-inline-block">
									<a
										rel="noreferrer"
										className="text-dark px-4 social-icon"
										style={socialIcon}
										href="https://twitter.com/sarahbcomphoto"
										target="_blank"
									>
										<FaTwitter />
									</a>
								</li>
								<li className="d-inline-block">
									<a
										rel="noreferrer"
										className="text-dark px-4 social-icon"
										style={socialIcon}
										href="https://www.facebook.com/sarahbennettcommercial/"
										target="_blank"
									>
										<FaFacebookSquare />
									</a>
								</li>
								<li className="d-inline-block">
									<a
										rel="noreferrer"
										className="text-dark px-4 social-icon"
										style={socialIcon}
										href="https://www.instagram.com/sarahbennettcommercialphoto/"
										target="_blank"
									>
										<FaInstagram />
									</a>
								</li>
								<li className="d-inline-block   ">
									<a
										rel="noreferrer"
										className="text-dark ps-md-4  social-icon"
										style={socialIcon}
										href="https://www.linkedin.com/in/sarahbennettcommercial/"
										target="_blank"
									>
										<FaLinkedin />
									</a>
								</li>
							</ul>
						</Col>
					</Row>
					<Row id="quote-form">
						<Col>
							<p className="mt-8 mb-4 text-center">
								Please use this form to request a quote for our services. We
								will get back to you within 24 hours.
							</p>
						</Col>
					</Row>
					<Row className="mb-8">
						<Col>
							{/* <div className="avenir-font" dangerouslySetInnerHTML={{ __html: html }} /> */}
							<ContactForm className="avenir-font" />
						</Col>
					</Row>
					<Row className="mb-8">
						<Col>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10090.968490358087!2d0.2649398!3d50.7803538!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dfba0f15946a47a!2sSarah%20Bennett%20Commercial%20Photography!5e0!3m2!1sen!2sae!4v1615276872440!5m2!1sen!2sae"
								width="100%"
								height="450"
								style={{ border: "0" }}
								allowFullScreen="true"
								loading="lazy"
							></iframe>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

const socialIcon = {
	fontSize: "2rem",
};

export default ContactUsPage;
